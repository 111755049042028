.inline-question-container {
    border: 1px solid;
    border-left: 0.5em solid;
    border-radius: 5px;
    width: 99%;
}

app-question .sec-2.spacing-sec-2 {
    padding-top: 92px;
}

.singlequestion-list,
.multiquestion-list {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    margin-bottom: 110px !important;

    @media screen and (max-width: 600px) {
        margin-bottom: 150px !important;
    }

    h4 {
        font: normal normal 400 20px/28px inherit;
        //@extend %theme-font-family;
    }

    li,
    .pt-dropdown-div {
        width: 100%;
        float: left;
        display: flex;
        flex-direction: column;

        .sql-title {
            font: 12px/16px;
            font-weight: 400;
            // font: normal normal 400 12px/16px inherit;
            margin-bottom: 5px;
        }

        .sql-ques {
            margin-bottom: 25px;

            table,
            th,
            td {
                border: 1px solid;
                padding: 5px;
            }
        }

        .sql-number,
        .sql-ques b {
            font: normal normal 500 16px/22px inherit;
        }

        .sql-ans-box {
            //width: 600px;
            float: left;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        .sql-option-box {
            width: auto;
            float: left;
            padding: 10px 15px;
            background: white;
            display: flex;
            align-items: center;
            border: 1px solid #e1dfdd;
            margin-bottom: 5px;
            border-radius: 3px;
        }
    }
}

.singleresult-list,
.multiresult-list {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    margin-bottom: 0;

    h4 {
        font: normal normal 400 20px/28px inherit;
    }

    li {
        width: 100%;
        float: left;
        display: flex;
        flex-direction: column;

        .sql-title {
            font: normal normal 400 12px/16px inherit;
            margin-bottom: 5px;
        }

        .sql-ques {
            margin-bottom: 25px;

            table,
            th,
            td {
                border: 1px solid #e1dfdd;
                padding: 5px;
            }
        }

        .sql-number,
        .sql-ques b {
            font: normal normal 500 20px/28px inherit;
            color: #e1dfdd;
        }

        .sql-ans-box {
            //width: 600px;
            float: left;
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .pe-none {
                pointer-events: none;
            }
        }

        .sql-option-box {
            width: auto;
            float: left;
            padding: 10px 15px;
            background: #ffffff;
            display: flex;
            align-items: center;
            border: 1px solid #e1dfdd;
            margin-bottom: 5px;
            border-radius: 3px;
        }
    }

    //min-height: calc(100vh - 21vh);
}

.sql-option-box-success,
.sql-option-box.notAttempt,
.build-list-option,
.match-option-li {
    border: 1px solid !important;
    border-color: #498205;
    color: #498205;

    .ts-checkbox-fluent {
        &+label:before {
            border-color: #498205;
        }

        &:checked {
            &+label:before {
                background-color: #b7dc87;
            }

            &+label:after {
                background-color: #107c10;
                box-shadow: 2px 0 0 #107c10, 4px 0 0 #107c10, 4px -2px 0 #107c10, 4px -4px 0 #107c10, 4px -6px 0 #107c10, 4px -8px 0 #107c10;
            }
        }
    }
}

.sql-option-box-failure {
    border: 1px solid;
    border-color: #d13438 !important;
    color: #d13438;

    .ts-checkbox-fluent {
        &+label:before {
            border-color: #d13438 ;
        }

        &:checked {
            &+label:before {
                background-color: #f3d6d8;
            }

            &+label:after {
                background-color: #a80000;
                box-shadow: 2px 0 0 #a80000, 4px 0 0 #a80000, 4px -2px 0 #a80000, 4px -4px 0 #a80000, 4px -6px 0 #a80000, 4px -8px 0 #a80000;
            }
        }
        ::before {
            background-color: #f3d6d8;
        }
        :before {
            background-color: #f3d6d8;
        }
    }
}

cloudlabs-doc-guide, cloudlabs-lab-guide {
    .sql-option-box-failure {
        .ts-checkbox-fluent {
            &:before {
                background-color: #f3d6d8;
            }
            &:after {
                background-color: #a80000;
                box-shadow: 2px 0 0 #a80000, 4px 0 0 #a80000, 4px -2px 0 #a80000, 4px -4px 0 #a80000, 4px -6px 0 #a80000, 4px -8px 0 #a80000;
            }
        }
    }
    .sql-option-box-success {
        .ts-checkbox-fluent {
            &:before {
                background-color: #b7dc87;
            }
            &:after {
                background-color: #107c10;
                box-shadow: 2px 0 0 #107c10, 4px 0 0 #107c10, 4px -2px 0 #107c10, 4px -4px 0 #107c10, 4px -6px 0 #107c10, 4px -8px 0 #107c10;
            }
        }
    }

}

.multiquestion-list li .mql-option-box.notAttempt,
.multiresult-list li .mql-option-box.notAttempt,
ul.swap-left-list-box li.rightAnswer {
    border-color: #498205;
    color: #498205
}

.singlequestion-list-result>li,
.multiquestion-list-result>li {
    padding: 20px 0 !important;
}

.singlequestion-list>li,
.singleresult-list>li {
    padding: 20px;
}


.ts-radio-fluent {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: 0px;
    cursor: pointer;
    position: absolute !important;
    opacity: 1;
    top: auto;

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        width: fit-content;
        word-break: break-word;
    }

    &.w-100 {
        &+label {
            width: 100%;
        }
    }

    // Box.
    &+label:before,
    &:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 1px solid #bebbb8 !important;
        border-radius: 100%;
        top: 0px;
        position: relative;
        float: left;
        padding: 9px;
    }

    // Box hover
    &:hover+label:before,
    &:before {
        background: #ffffff;
    }

    // Box focus
    &:active+label:before,
    &:active+ :before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked {

        +label:before,
        &:before {
            background-color: #0067b8;
        }
    }

    // Disabled state label.
    &:disabled+label {
        color: #bebbb8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #f5f5f5;
    }

    // Disabled and checked box.
    &:disabled:checked {

        +label:before,
        &:before {
            box-shadow: none;
            background: #bebbb8;
        }
    }

    // Checkmark. Could be replaced with an image
    &:checked {

        +label:after,
        &:after {
            content: "";
            position: absolute;
            left: 5px;
            top: 9px;
            background: #ffffff;
            width: 2px;
            height: 2px;
            box-shadow:
                2px 0 0 #ffffff,
                4px 0 0 #ffffff,
                4px -2px 0 #ffffff,
                4px -4px 0 #ffffff,
                4px -6px 0 #ffffff,
                4px -8px 0 #ffffff;
            transform: rotate(45deg);
        }
    }
}

.ts-radio-fluent-success {

    &+label:before,
    &:before {
        background-color: #ffffff;
        border-color: #498205
    }

    &+label {
        color: #498205 !important;
        ;
    }

    &:checked {

        +label:before,
        &:before {
            background-color: #b7dc87;
        }

        &+label:after,
        &:after {
            background-color: #107c10;
            box-shadow:
                2px 0 0 #107c10,
                4px 0 0 #107c10,
                4px -2px 0 #107c10,
                4px -4px 0 #107c10,
                4px -6px 0 #107c10,
                4px -8px 0 #107c10;
        }
    }
}

.ts-radio-fluent-failure {

    &+label:before,
    &:before {
        background-color: #ffffff;
        border-color: #f3d6d8 !important;
    }

    &+label,
    span {
        text-decoration: line-through #a80000;
        color: #a80000;
    }

    &:checked {

        +label:before,
        &:before {
            background-color: #f3d6d8;
            border-color: #d13438
        }

        +label:after,
        &:after {
            background-color: #a80000;
            box-shadow:
                2px 0 0 #a80000,
                4px 0 0 #a80000,
                4px -2px 0 #a80000,
                4px -4px 0 #a80000,
                4px -6px 0 #a80000,
                4px -8px 0 #a80000;
        }
    }
}

.ts-radio-fluent-primary {
    &+label:before {
        background-color: #ffffff !important;
    }
}

.ts-radio-fluent+label .ts-radio-fluent-text {
    display: inline-block;
    width: calc(100% - 40px);
    text-decoration: inherit;
}

.ts-radio-fluent-failure span.ts-radio-fluent-text {
    text-decoration: line-through;
}

.icon-cursor {
    cursor: pointer;
}

teams-radio > label, teams-checkbox > label {
    margin-bottom: 0px !important;
}


.ts-checkbox-fluent {
    accent-color: var(--cloudlabs-primary-color);
    height: 20px;
    width: 20px;
    margin-right: 7px;
    margin-top: 2px;
    cursor: pointer;
    position: absolute;
    opacity: 1;
    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        width: 100%;
        word-break: break-word;
    }
    // Box.
    & + label:before,
    &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 1px solid #bebbb8 !important;
        border-radius: 3px;
        top: 0px;
        position: relative;
        float: left;
    }
    // Box hover
    &:hover + label:before,
    &:hover :before {
        background: #ffffff;
    }
    // Box focus
    &:active + label:before,
    &:active :before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    // Box checked
    &:checked + label:before {
        background-color: var(--cloudlabs-primary-color);
        &:hover {
            background-color: var(--cloudlabs-primary-hover-color);
        }
        &:active {
            background-color: var(--cloudlabs-primary-focus-color);
        }
    }
    // Box checked for pt
    &:checked {
        &:before {
            background-color: var(--cloudlabs-primary-color);
            &:hover {
                background-color: var(--cloudlabs-primary-hover-color);
            }
            &:active {
                background-color: var(--cloudlabs-primary-focus-color);
            }
        }
    }
    // Disabled state label.
    &:disabled + label,
    &:disabled {
        color: #bebbb8;
        cursor: auto;
    }
    // Disabled box.
    &:disabled + label:before,
    &:disabled + :before {
        box-shadow: none;
        background: #f5f5f5;
    }
    // Disabled and checked box.
    &:disabled:checked + label:before,
    &:disabled:checked + :before {
        box-shadow: none;
        background: #bebbb8;
    }
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: #ffffff;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 #ffffff,
            4px 0 0 #ffffff,
            4px -2px 0 #ffffff,
            4px -4px 0 #ffffff,
            4px -6px 0 #ffffff,
            4px -8px 0 #ffffff;
        transform: rotate(45deg);
    }
    // Checkmark. Could be replaced with an image for pt
    &:checked {
        &:after {
            content: "";
            position: absolute;
            left: 5px;
            top: 9px;
            background: #ffffff;
            width: 2px;
            height: 2px;
            box-shadow:
                2px 0 0 #ffffff,
                4px 0 0 #ffffff,
                4px -2px 0 #ffffff,
                4px -4px 0 #ffffff,
                4px -6px 0 #ffffff,
                4px -8px 0 #ffffff;
            transform: rotate(45deg);
        }
    }
    //for non border and background-color filled checkbox
    &.checkbox-square + label:before {
        background: #f3f6f9;
        // border: 1px solid transparent !important;
    }
}

.ts-checkbox-fluent-success {
    & + label:before,
    &:before {
        background-color: #ffffff;
        border-color: #498205  !important;
    }
    & + label {
        color: #498205  !important;;
    }
    &:checked {
        + label:before,
        &:before {
            background-color: #b7dc87  !important;
        }
        & + label:after,
        &:after {
            background-color: #107c10;
            box-shadow:
                2px 0 0 #107c10,
                4px 0 0 #107c10,
                4px -2px 0 #107c10,
                4px -4px 0 #107c10,
                4px -6px 0 #107c10,
                4px -8px 0 #107c10;
        }
    }
}

.ts-checkbox-fluent-failure {
    & + label:before,
    & :before {
        background-color: #ffffff;
        border-color: #f3d6d8;
    }
    & + label,
    & + span {
        text-decoration: line-through #a80000;
        color: #a80000;
    }
    &:checked {
        + label:before,
        &:before {
            background-color: #f3d6d8;
            border-color: #d13438
        }
        & + label:after,
        &:after {
            background-color: #a80000;
            box-shadow:
                2px 0 0 #a80000,
                4px 0 0 #a80000,
                4px -2px 0 #a80000,
                4px -4px 0 #a80000,
                4px -6px 0 #a80000,
                4px -8px 0 #a80000;
        }
    }
}

.ts-checkbox-fluent-primary {
    & + label:before {
        background-color: #ffffff !important;
    }
}
