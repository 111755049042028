/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "prismjs/themes/prism-okaidia.css";
@import "prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.css";
// @import "./style/_variable.scss";

.navBtns {
  .expand-link {
    text-decoration: underline;
  }
}
.appfooter {
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
}
body .guideBlock_2 .contentHeight .tab-wrp .content-wrp {
  height: 54vh;
}
img {
  max-width: 100%;
}
#instructions-azure-login a {
  text-decoration: underline;
}
.page-1-heading h3 {
  display: inline-block;
}
.guideBlock_2 .tabs_2 {
  display: flex !important;
}
.toast-success {
  //background-image: none !important;
  background-color: #328632 !important;
}
.ngx-remote-desktop-display {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 999999;
  right: 0;
}
.ngx-spinner {
  display: none !important;
}
.actionIcons {
  font-size: 18px;
  padding: 0px;
  position: ABSOLUTE;
  right: 10px;
  top: 10px;
}

.guideBlock.vmSideBar .table-responsive {
  tr th:nth-child(1) {
    width: 45% !important;
  }
}
.guideBlock.vmSideBar .table-responsive {
  .btnValidate {
    font-size: 12px;
    width: 100%;
    padding: 4px 8px;
  }
}

cloudlabs-main cloudlabs-lab .portlet.box{
  padding: 0px 25px;
  background-color: #fff;
}

.guideBlock_2 {
  background-color: #fff;
  top: 60px !important;
  .tab-wrpguide {
    opacity: 1 !important;
  }
}

.main {
  .innerSection .dropdown-menu {
    left: inherit !important;
    right: 0;
    transform: inherit !important;
    top: 25px !important;
  }
}

.guideBlock .tab-wrpguide {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -ms-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.sideNavBar .guideBlock .tab-wrpguide {
  opacity: 1;
}
.guideBlock .lab_Urls {
  display: none;
}
.guideBlock .btn-group {
  width: 100% !important;
  margin: 0 5px;
  .btn {
    font-size: 16px;
  }
}

.tab-wrpguide {
  position: relative;
  .nav-tabs {
    padding-left: 2px;
    // margin-left: 15px;
    /* margin-right: 15px; */
    /* padding-top: 4px; */
    min-height: 44px;
    padding-bottom: 8px;
    border-color: #E4E6EF;

    li {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 23px;
        background-color: #dee2e6;
        right: -3px;
        top: 10px;
      }
    }

  }
}
#lab-guideview {
  .variable-binding {
    pre {
      width: 95%;
    }
    .copyelement {
      color: #000 !important;
      position: relative;
    }
  }
}

.variable-binding {
  span.copyPasteDetails {
    word-break: break-all;
    position: relative;
    span {
      visibility: visible;
      font-size: 1rem;
    }
  }
}

.variable-binding {
  span.copydetails {
    // font-size: 0;
    word-break: break-all;
    position: relative;
    visibility: hidden;
    span {
      visibility: visible;
      font-size: 1em;
    }
    a {
      visibility: visible;
      outline: none;
      font-family: inherit;
      min-width: 10px;
      width: 20px;
      height: 20px;
      position: relative;
      &::before {
        font-family: "FontAwesome"; // bug no 400 fix
        // font-family: "Font Awesome 5 Free";
        line-height: 20px;
      }
    }

    &.hide-copy-btn {
      a {
        visibility: hidden;
        width: 0px;
        min-width: 0px;
        margin-left: 0px;
      }
    }
  }
}
// .variable-binding {
//   p.copydetails{
//     font-size: 0;
//     word-break: break-all;
//     position: relative;
//     visibility: hidden;
//     span {
//       visibility: visible;
//       font-size: 1rem;
//     }
//     a {
//       visibility: visible;
//       outline: none;
//       font-family: inherit;
//       min-width: 10px;
//       width: 20px;
//       height: 20px;
//       overflow: visible;
//       padding-top: 20px;
//       position: relative;
//       font-size: 0px;
//       &::before {
//         font-family: "FontAwesome";
//         line-height: 20px;
//         top: -20px;
//       }
//     }

//     &.hide-copy-btn {
//       a {
//         display: none;
//       }
//     }
//   }
// }

#guideView {
  .variable-binding {
    pre {
      width: 92%;
      // a {
      //     right: -25px;
      //     color: #000 !important;
      //   }
      
      //   a:focus {
      //     outline: 1px solid black;
      //     outline-offset: 2px;
      //   }
      
        //To avoid horizontal scroll in code block uncomment this code 
        // code {
        //   white-space: pre-line !important;
        //   word-break: break-word !important;
        // }
    }
    .copyelement{
      color: #000 !important;
      position: relative;
    }
    // .copyelement:focus{
    //   outline-color: black !important;
    //   outline-style: dotted !important;
    //   outline-width: 3px !important;
    //   outline-offset: 6px !important;
    // }
  }
}
#myTabContent,
#lab-guide-tab {
  .guide-tabs {
    display: none;
  }
  .guide-tabs.showtab {
    display: flex;
    flex-flow: column;
    overflow-x: hidden !important;
    height: inherit;
  }
}
#lab-environment-tab {
  .guide-tabs {
    display: none;
  }
  .guide-tabs.showtab {
    display: inherit;
  }
}
.refressbtn {
  span {
    font-family: SegoeUI-regular, sans-serif, Arial, Helvetica !important;
  }
}

body div.guideBlock_2 .tab-wrp .content-wrp {
  // height: 72vh;
}
.guideBlock .tab-wrp .content-wrp {
  // height: 70vh;
}
.guideBlock {
  .tab-wrp.tab-wrpguide {
    padding-bottom: 0 !important;
  }
  overflow-y: hidden;
  height: 100vh;
}

.guideBlock cloudlabs-doc-guide #guideView {
  padding-bottom: 35px;
}
.guideBlock cloudlabs-doc-guide cloudlabs-lab-environment .btn-group .btn {
  font-size: 12px;
  margin: 0 5px !important;
}
.guideBlock cloudlabs-doc-guide pagination-controls ul {
  margin: 0 auto 0px;
  padding-left: 0;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.guideBlock cloudlabs-doc-guide cloudlabs-pagination-controls ul {
  margin: 0 auto 0px;
  padding-left: 0;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.guideBlock.guideBlock_2 cloudlabs-doc-guide cloudlabs-lab-environment .btn-group .btn {
  font-size: 14px;
}
.guideBlock app-doc-guide pagination-controls {
  // position: absolute;
  // bottom: 20px;
  // background: #fff;
  // padding-top: 0px;
  // display: block;
  // width: 100%;
  // left: 0;
  // margin-bottom: 4px;
}

#myTabContent #guideView markdown img {
  max-width: 100%;
}
markdown img {
  max-width: 100%;
}
.stretch .guideBlock .tab-wrp .content-wrp {
  height: 75vh;
}

.guideBlock {
  .tab-content {
    .variable-binding {
      // h2,
      // h3 {
      //   font-size: 18px;
      // }
      p {
        // font-size: 14px;
      }
    }
  }
}
.portlet #accordionExample:hover {
  color: inherit;
}

:root {
  scrollbar-color: #d1d1d1 #eee !important;
  scrollbar-width: 7px !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
}

#vex-splash-screen,
.vex-splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #13293d;
  z-index: 999999;
  pointer-events: none;
  overflow: hidden;
}
#vex-splash-screen > .wrapper,
.vex-splash-screen > .wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}
.ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.ball-scale-multiple > div {
  background-color: $white;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  left: -30px;
  top: 0;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
  animation: ball-scale-multiple 1s 0s linear infinite;
}
.copyelement::before {
  font-size: 18px;
  position: relative;
  top: 1px;
}
cloudlabs-doc-guide .hide-name {
  display: none;
}
pre {
  overflow: auto !important;
  .copyelement {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    width: 18px;
    height: 20px;
    overflow: hidden;
  }
  .copyelement.show-copy-btn::before {
    display: inherit;
  }

  .copyelement.show-copy-btn::before {
    display: none;
  }
}
.copydetails .copyelement {
  margin-left: 6px;
  cursor: pointer;
}
.copyelement {
  font-size: 0 !important;
}
.variable-binding {
  pre {
    position: relative;
    overflow: visible;
    word-break: break-all;
    padding-bottom: 25px;
    code {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}

.tab-content {
  .variable-binding {
    h1 {
      font-size: 2em;
      font-weight: 600;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 600;
    }
    h3 {
      font-size: 1.25em;
      font-weight: 600;
    }
    h4 {
      font-size: 1em;
      font-weight: 600;
    }
  }
}
.tab-content .lab-guide .variable-binding {
  h1 {
    font-size: 1.7em;
    font-weight: unset;
  }
  h2 {
    font-weight: unset;
  }
  h3 {
    font-weight: unset;
  }
  h4 {
    font-weight: unset;
  }
}
cloudlabs-lab-validation .accordion:hover i {
  color: inherit;
}

.stretch .guideBlock .tab-wrp .content-wrp {
  // height: calc(100vh - 160px);
}
body .guideBlock_2 .contentHeight .tab-wrp .content-wrp {
  // height: calc(100vh - 284px);
}
body div.guideBlock_2 .tab-wrp .content-wrp {
  // height: calc(100vh - 180px);
}

.guideBlock .tab-wrp .content-wrp {
  // height: calc(100vh - 185px);
}
@media screen and (max-width: 1920px) {
  .sideNavBar .guideBlock .hide-column {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .sideNavBar .guideBlock .tab-wrp .nav-tabs li a {
    padding: 0.5rem 10px;
  }
}
@media screen and (max-width: 1440px) {
  .sideNavBar .guideBlock .tab-wrp .nav-tabs li a {
    padding: 0.5rem 6px;
  }
}
@media screen and (max-width: 1366px) {
  .sideNavBar .guideBlock .tab-wrp .nav-tabs li a {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 640px) {
  .guideBlock.guideBlock_2 .tab-wrp .nav-tabs li a {
    font-size: 13px !important;
    padding: 0.5rem 6px;
  }
}
@media screen and (max-width: 1080px) {
  .guideBlock.guideBlock_2 .hide-column {
    display: none;
  }
  .guideBlock_2 .table-responsive {
    tr th:nth-child(1) {
      width: 45% !important;
    }
  }

  .guideBlock_2 .table-responsive {
    .btnValidate {
      font-size: 12px;
      width: 100%;
      padding: 4px 8px;
    }
  }
}
@media screen and (max-width: 640px) {
  body .guideBlock_2 .contentHeight .tab-wrp .content-wrp {
    height: calc(100vh - 298px);
  }
  body div.guideBlock_2 .tab-wrp .content-wrp {
    height: calc(100vh - 180px);
  }
  .guideBlock.guideBlock_2 cloudlabs-doc-guide cloudlabs-lab-environment .btn-group .btn {
    font-size: 14px;
  }
  .guidefooter .footerBottom p {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    text-align: center !important;
  }
}

@media all and (max-width: 490px) {
  .appfooter {
    bottom: 0;
    left: 0;
    right: 0;
    position: relative;
  }
  .innerContent {
    height: auto;
    background: #fff;
    padding: 20px 50px 40px;
    width: 80%;
    position: absolute;
    // margin-top: -15% !important;
    margin-left: 7% !important;
    overflow: hidden;
    transform: none;
  }
  .navbar img {
    height: 17px !important;
    /* position: static;  */
  }
  .navbar {
    height: auto !important;
  }
  .innerSection {
    right: 0;
    /* overflow: none; */
    margin: 0px;
    position: unset;
    width: 100%;
    height: auto;
  }
  .offset-sm-6 {
    margin-left: 0px;
  }

  .two {
    height: auto;
    display: block;
  }

  .scrollTop {
    right: 4px !important;
    bottom: 6px !important;
  }
}

@keyframes ldio-bvwfzzyjjt {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.variable-binding span.copydetails a:focus {
  outline: 1px solid black;
  outline-offset: 3px;
}

.ldio-bvwfzzyjjt div {
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  -webkit-animation: ldio-bvwfzzyjjt linear 1s infinite;
  animation: ldio-bvwfzzyjjt linear 1s infinite;
  width: 3px;
  height: 10px;
  border-radius: 6px/10px;
  margin: auto;
  transform-origin: 3px 19px;
}
.ldio-bvwfzzyjjt div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
}
.ldio-bvwfzzyjjt div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
}
.ldio-bvwfzzyjjt div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}
.ldio-bvwfzzyjjt div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
}
.ldio-bvwfzzyjjt div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
}
.ldio-bvwfzzyjjt div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}
.ldio-bvwfzzyjjt div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
}
.ldio-bvwfzzyjjt div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
}
.ldio-bvwfzzyjjt div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}
.ldio-bvwfzzyjjt div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
}
.ldio-bvwfzzyjjt div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
}
.ldio-bvwfzzyjjt div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
.loadingio-spinner-spinner-gu4g40ggr97 {
  width: 38px;
  position: relative;
  height: 38px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-bvwfzzyjjt {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-bvwfzzyjjt div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */

// Lab guide table css

markdown {
  table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }

  table th,
  table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }

  table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

//      High-contrast mode

// Scroll bar
@media (-ms-high-contrast: active), (forced-colors: active) {
  ::-webkit-scrollbar-thumb {
    forced-color-adjust: none;
    // background-color: rgba(96 96 96 / 36%);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.24);
    //background-color:rgba(255, 255, 255, 0.3);
  }
}

// Progress bar in high-contrast mode
@media (-ms-high-contrast: active), (forced-colors: active) {
  .progress {
    forced-color-adjust: none;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .progress-bar {
    forced-color-adjust: none;
  }
}

// spinner
@media (-ms-high-contrast: active), (forced-colors: active) {
  .ldio-bvwfzzyjjt div {
    forced-color-adjust: none;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .toast-success,
  .toast-error,
  .toast-warning {
    forced-color-adjust: none;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .btn-secondary:focus,
  .btn-secondary.focus {
    forced-color-adjust: none;
  }
}

// @media (-ms-high-contrast: black-on-white),(forced-colors: active)  {
//   .nav-tabs .dropdown-menu{
//     background-color: black;
//     border: 1px solid #1aebff;
//   }
// }

@media screen and (-ms-high-contrast: white-on-black) {
  .nav-tabs .dropdown-menu {
    background-color: black;
    border: 1px solid #1aebff;
  }
}

@media screen and (-ms-high-contrast: black-on-white) {
  .nav-tabs .dropdown-menu {
    background-color: white;
    border: 1px solid black;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .tab-wrpguide ul li a.active {
    forced-color-adjust: none;
    border: 2px dashed #5757cb !important;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .tab-wrpguide ul li a.active:after {
    forced-color-adjust: none;
    background-color: #5757cb !important;
  }
}

@media (-ms-high-contrast: black-on-white), (forced-colors: active) {
  cloudlabs-pagination-controls ul.ngx-pagination li.current {
    forced-color-adjust: none;
    border: 2px dashed;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  #m-logo {
    forced-color-adjust: none;
    background-color: black !important;
  }
}

.tooltip .tooltip-inner {
  background-color: #fff !important;
  color: #404040;
  border: 0.1px solid #000000;
  border-radius: 0;
}
.tooltip .arrow {
  display: none !important;
}

.sql-title {
  font: normal normal 400 12px/16px inherit;
  margin-bottom: 5px;
  color: #0072C6;
}

cloudlabs-lab-validation {
  .inline-validation {
      padding: 12px 20px 20px 20px;
      margin-bottom: 10px;
      border: 1px solid #0072CA;
      border-left: 0.5em solid #0072CA ;
      border-radius: 5px;
    }
}
